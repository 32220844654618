import.meta.glob([
    '../img/**',
    '../fonts/**',
])

import * as Sentry from '@sentry/browser'

Sentry.init({
    dsn: "https://b88560822b88229ebbfef845b005acb5@o4507387305918464.ingest.us.sentry.io/4508423858421760",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: 0.1,
    tracePropagationTargets: [/^https:\/\/beardsorcery\.com/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
})

import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm'
import '../../vendor/spatie/laravel-comments-livewire/resources/js/comments.js'

import masonry from 'alpinejs-masonry'
Alpine.plugin(masonry)

import Splide from '@splidejs/splide'
import docCookies from 'cookies.js'

document.addEventListener('alpine:init', () => {
    Alpine.store('cart', {
        open: false,

        toggle() {
            if (Alpine.store('guild').open) {
                Alpine.store('guild').toggle()
            }

            this.open = !this.open
        },

        show() {
            this.open = true
        },

        hide() {
            this.open = false
        }
    })

    Alpine.store('guild', {
        open: false,

        toggle() {
            if (Alpine.store('cart').open) {
                Alpine.store('cart').toggle()
            }

            this.open = !this.open
        },

        show() {
            this.open = true
        },

        hide() {
            this.open = false
        }
    })

    Alpine.store('addToCart', {
        inview: true,
        started: false,

        inView() {
            this.inview = !this.started ? this.inview : true
        },

        outOfView() {
            this.inview = !this.started ? this.inview : false
        },

        start(el) {
            const box = el.getBoundingClientRect()

            this.started = true
            this.inview = box.top < window.innerHeight && box.bottom >= 0
        }
    })
})

window.addEventListener('analytics-consented', event => {
    docCookies.setItem('___cookie_consent', true, 31536e3, '/', import.meta.env.VITE_SESSION_DOMAIN, true, "lax")
})

window.addEventListener('analytics-activity', event => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(event.detail.output)
})

window.addEventListener('cart-submission-complete', event => {
    Alpine.store('cart').show()
})

// Livewire go
window.Livewire = Livewire
Livewire.start()

// Splide go
window.Splide = Splide
import '@splidejs/splide/css/core'
